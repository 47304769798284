import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'

const PaymentError = () => {

    return(
        <Container style={{marginTop: '4rem'}}>
            <Row>
                <Col xs={12}>
                    <div className="alert alert-danger" role="alert">
                        <div style={{display: 'flex'}}>
                            <div>
                                <h3>
                                    <FontAwesomeIcon icon={faTimesCircle} />
                                </h3>
                            </div>
                            <div style={{marginLeft: '1rem'}}>
                                <h3>
                                    Płatność nie powiodła się! 
                                </h3>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12}>
                    Jeśli masz pytania napisz do nas: <a href="mailto:biuro@kozackafura.pl">biuro@kozackafura.pl</a>
                </Col>
                <Col xs={12} style={{marginTop: '1rem'}}>
                    <a href="https://bilet.kozackafura.pl/">
                        <Button
                            color="success"
                            style={{background: '#e5bc42', border: '1px solid #e5bc42', fontWeight: 'bold'}}>
                            BILETY
                        </Button>
                    </a>
                    <a href="https://kozackafura.pl" style={{marginLeft: '1rem'}}>
                        <Button
                            color="success"
                            style={{background: '#e5bc42', border: '1px solid #e5bc42', fontWeight: 'bold'}}>
                            KOZACKAFURA.PL
                        </Button>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}

export default PaymentError;