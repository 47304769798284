import React from 'react';
import Logo from '../assets/img/kozackafura-logo.png';

//'linear-gradient(#0c0c0c,#1e1e1e)'
const Navbar = () => {
    return(
        <div style={{width: '100vw',height: '100px',display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'rgb(12,12,12)'}}>
            <img src={Logo} alt='logo' style={{width: '100px'}} />
        </div>
    )
}

export default Navbar;