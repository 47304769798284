import React, { useEffect } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { Redirect, useLocation } from 'react-router-dom';

const sendMailOrder = async(orderid) => {
    const response = await fetch('https://bilety.sukces24.usermd.net/stripe/sendMail', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({orderID: orderid})
    });
    if(response.status !== 200){
        window.location.href = 'https://bilet.kozackafura.pl/'
    }
    const data = await response.json();
}

const PaymentSuccess = () => {
    let { search } = useLocation();
    useEffect(() => {
        const query = new URLSearchParams(search);
        const orderid = query.get('orderid');
        if(orderid === null)
            return <Redirect to="/" />
        sendMailOrder(orderid);
    }, []);
    return(
        <Container style={{marginTop: '4rem'}}>
            <Row>
                <Col xs={12}>
                    <div className="alert alert-success" role="alert">
                        <div style={{display: 'flex'}}>
                            <div>
                                <h3>
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </h3>
                            </div>
                            <div style={{marginLeft: '1rem'}}>
                                <h3>
                                    Dziękujemy za zakup! 
                                </h3>
                                <div>
                                    Bilety zostały wsyłane na Twój adres e-mail. 
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={12}>
                    Jeśli masz pytania napisz do nas: <a href="mailto:biuro@kozackafura.pl">biuro@kozackafura.pl</a>
                </Col>
                <Col xs={12} style={{marginTop: '1rem'}}>
                    <a href="https://bilet.kozackafura.pl/">
                        <Button
                            color="success"
                            style={{background: '#e5bc42', border: '1px solid #e5bc42', fontWeight: 'bold'}}>
                            BILETY
                        </Button>
                    </a>
                    <a href="https://kozackafura.pl" style={{marginLeft: '1rem'}}>
                        <Button
                            color="success"
                            style={{background: '#e5bc42', border: '1px solid #e5bc42', fontWeight: 'bold'}}>
                            KOZACKAFURA.PL
                        </Button>
                    </a>
                </Col>
            </Row>
        </Container>
    )
}

export default PaymentSuccess;