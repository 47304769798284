import React, {useState} from 'react';
import { Form, FormGroup, Label, Input, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons'
import { loadStripe } from '@stripe/stripe-js';
const stripePromise = loadStripe('pk_live_51HgRl9Kl82gMbbG6ufIuiDjOJ1UIrQ0Zfdjp1ghD0uTiSjCM6o5LTg9R0ZUc8RN8H38LV41GrflCHpTxzTI6aQh000uASZkLPO');

const handleClick = async (drivers, pasagers, person) => {
    // console.log(drivers)
    // console.log(pasagers)
    const stripe = await stripePromise;
    const response = await fetch('https://bilety.sukces24.usermd.net/stripe/createSession', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({drivers: drivers, pasagers:pasagers, person: person})
    });
    const session = await response.json();
    // When the customer clicks on the button, redirect them to Checkout.
    const result = await stripe.redirectToCheckout({
        sessionId: session.id,
    });
}

const Person = ({driver, pasager}) => {
    const [personData, setPersonData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    })
    const submitForm = (e) => {
        e.preventDefault();
        if(driver > 0 || pasager > 0){
            handleClick(
                driver,
                pasager,
                personData
            )
        }
    }
    return(
        <Form onSubmit={(e)=>submitForm(e)}>
            <Row>
                <div className="col-md-6">
                    <FormGroup>
                        <Label>Imię</Label>
                        <Input 
                            type="text" 
                            value={personData.firstName}
                            style={{border: '2px solid #e5bc42', borderRadius: '1rem'}}
                            onChange={(e)=>setPersonData({...personData, ...{firstName: e.target.value}})}
                            required />
                    </FormGroup>
                </div>
                <div className="col-md-6">
                    <FormGroup>
                        <Label>Nazwisko</Label>
                        <Input 
                            type="text" 
                            value={personData.lastName}
                            style={{border: '2px solid #e5bc42', borderRadius: '1rem'}}
                            onChange={(e)=>setPersonData({...personData, ...{lastName: e.target.value}})}
                            required />
                    </FormGroup>
                </div>
                <div className="col-md-6">
                    <FormGroup>
                        <Label>E-mail</Label>
                        <Input 
                            type="email" 
                            value={personData.email}
                            style={{border: '2px solid #e5bc42', borderRadius: '1rem'}}
                            onChange={(e)=>setPersonData({...personData, ...{email: e.target.value}})}
                            required />
                    </FormGroup>
                </div>
                <div className="col-md-6">
                    <FormGroup>
                        <Label>Telefon</Label>
                        <Input 
                            type="phone" 
                            maxLength={9}
                            minLength={9}
                            value={personData.phone}
                            style={{border: '2px solid #e5bc42', borderRadius: '1rem'}}
                            onChange={(e)=>setPersonData({...personData, ...{phone: e.target.value}})}
                            required />
                    </FormGroup>
                </div>
                <div className="col-12 mt-3 text-right" style={{paddingBottom: '5rem'}}>
                    <button 
                        type="submit" 
                        className="btn btn-success mt-3" 
                        style={{borderRadius: '50px', padding: '.7rem 1.7rem', fontWeight: 'bold', background: '#e5bc42', border: '1px solid #e5bc42'}}>
                        KUPUJE
                        <FontAwesomeIcon icon={faShoppingCart} style={{marginLeft: '.25rem'}} />
                    </button> 
                </div>
            </Row>
        </Form>
    )
}

export default Person;