import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Basket from "./views/Basket";
// import Home from "./views/Home";
import Navbar from "./views/Navbar";
import PaymentError from "./views/PaymentError";
import PaymentSuccess from "./views/PaymentSuccess";

function App() {
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path='/complete'>
          <PaymentSuccess />
        </Route>
        <Route path='/cancel'>
          <PaymentError />
        </Route>
        <Route path='/koszyk'>
          <Basket />
        </Route>
        <Route path='/'>
          <Basket />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
