import React, {useState, useEffect} from 'react';
import './Basket.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faShoppingCart, faUser } from '@fortawesome/free-solid-svg-icons'
import ProductIMG from '../assets/img/bilet.png';
// import ProductIMG from '../assets/img/_DSC4317.jpg';
import Person from './Person';
import Info from './Info';

const Basket = () => {
    const [driver, setDriver] = useState(0)
    const [pasager, setPasager] = useState(0)
    const [summary, setSummary] = useState(0)
    useEffect(() => {
        let price = driver * 300;
        price = price + (pasager * 150)
        setSummary(price)
    }, [driver])
    useEffect(() => {
        let price = pasager * 150;
        price = price + (driver * 300)
        setSummary(price)
    }, [pasager])
    return(
        <div className="basket">
            <Info />
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h4 style={{color: '#e5bc42'}}>
                            Koszyk
                            <FontAwesomeIcon style={{marginLeft: '.5rem', fontSize: '22px'}} icon={faShoppingCart} /> 
                        </h4>
                    </div>
                    <div className="col-12 basket__body">
                        <div className="row">
                            <div className="col-12 basket__body--item">
                                <div className="row" style={{padding: 0, margin: 0, border: '1px solid #e5bc42', borderTopRightRadius: '5rem', borderBottomRightRadius: '5rem'}}>
                                    <div className="col-3 basket--box-img" style={{margin: 0, padding: 0}}>
                                        <img src={ProductIMG} className="basket--img" />
                                    </div>
                                    <div className="col-5" style={{fontWeight: 'bold'}}>
                                        <div style={{display: 'flex', flexFlow:'column', width: '100%', height: '100%', justifyContent: 'center'}}>
                                            <div style={{fontSize: '14px', color: '#e5bc42'}}>
                                                ZŁOTY BILET
                                            </div>
                                            <div style={{fontSize: '16px'}}>
                                                KIEROWCY
                                            </div>
                                            <div className="latoFont" style={{fontSize: '16px'}}>300zł</div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="d-sm-block d-block d-md-none" style={{height: '100%'}}>
                                            <div style={{display: 'flex', flexFlow:'column', width: '100%', height: '100%', justifyContent: 'center'}}>
                                                <input
                                                    min={0}
                                                    className="basket--input"
                                                    type="number"
                                                    value={driver}
                                                    onChange={(e)=>setDriver(e.target.value)}
                                                    onBlur={(e)=>e.target.value===''?setDriver(0):setDriver(Number(e.target.value))}
                                                />
                                            </div> 
                                        </div>
                                        <div className="d-sm-none d-none d-md-block" style={{height: '100%'}}>
                                        {
                                            driver > 0 ? 
                                            <div style={{display: 'flex', flexFlow:'column', width: '100%', height: '100%', justifyContent: 'center'}}>
                                                <input
                                                    className="basket--input"
                                                    type="number"
                                                    value={driver}
                                                    onChange={(e)=>setDriver(Number(e.target.value))}
                                                />
                                            </div> 
                                            :
                                            <div style={{display: 'flex', flexFlow:'column', width: '100%', height: '100%', justifyContent: 'center'}}>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-success mt-3" 
                                                    style={{borderRadius: '50px', padding: '.7rem 1.7rem', background: '#e5bc42', border: '1px solid #e5bc42', fontSize: '15px'}}
                                                    onClick={()=>setDriver(Number(1))}>
                                                    DODAJ BILET
                                                </button> 
                                            </div>
                                        }
                                        </div>
                                    </div>
                                    <div className="col-2 text-center" style={{fontSize: '21px', cursor:'pointer'}}>
                                        <div style={{display: 'flex', flexFlow:'column', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                            <div onClick={()=>setDriver(0)} className="hoverRed">
                                                <FontAwesomeIcon icon={faTimes} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 basket__body--item">
                                <div className="row" style={{ padding: 0, margin: 0, border: '1px solid #e5bc42', borderTopRightRadius: '5rem', borderBottomRightRadius: '5rem'}}>
                                    <div className="col-3 basket--box-img" style={{margin: 0, padding: 0}}>
                                        <img src={ProductIMG} className="basket--img" />
                                    </div>
                                    <div className="col-5" style={{fontWeight: 'bold'}}>
                                        <div style={{display: 'flex', flexFlow:'column', width: '100%', height: '100%', justifyContent: 'center'}}>
                                            <div style={{fontSize: '14px', color: '#e5bc42'}}>
                                                ZŁOTY BILET
                                            </div>
                                            <div style={{fontSize: '16px'}}>
                                                PASAŻERA
                                            </div>
                                            <div className="latoFont" style={{fontSize: '16px'}}>150zł</div>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <div className="d-sm-block d-block d-md-none" style={{height: '100%'}}>
                                            <div style={{display: 'flex', flexFlow:'column', width: '100%', height: '100%', justifyContent: 'center'}}>
                                                <input
                                                    min={0}
                                                    className="basket--input"
                                                    type="number"
                                                    value={pasager}                                                    
                                                    onChange={(e)=>setPasager(e.target.value)}
                                                    onBlur={(e)=>e.target.value===''?setPasager(0):setPasager(Number(e.target.value))}
                                                />
                                            </div> 
                                        </div>
                                        <div className="d-sm-none d-none d-md-block" style={{height: '100%'}}>
                                            {
                                            pasager > 0 ? 
                                            <div style={{display: 'flex', flexFlow:'column', width: '100%', height: '100%', justifyContent: 'center'}}>
                                                <input
                                                    className="basket--input"
                                                    type="number"
                                                    value={pasager}
                                                    onChange={(e)=>setPasager(Number(e.target.value))}
                                                />
                                            </div> 
                                            :
                                            <div style={{display: 'flex', flexFlow:'column', width: '100%', height: '100%', justifyContent: 'center'}}>
                                                <button 
                                                    type="button" 
                                                    className="btn btn-success mt-3" 
                                                    style={{borderRadius: '50px', padding: '.7rem 1.7rem', background: '#e5bc42', border: '1px solid #e5bc42', fontSize: '15px'}}
                                                    onClick={()=>setPasager(Number(1))}>
                                                    DODAJ BILET
                                                </button> 
                                            </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="col-2 text-center" style={{fontSize: '21px'}}>
                                        <div style={{display: 'flex', flexFlow:'column', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center'}}>
                                            <div onClick={()=>setPasager(0)} className="hoverRed">
                                                <FontAwesomeIcon style={{cursor:'pointer'}} icon={faTimes} /> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 basket__footer">
                        <div className="row" style={{margin:0,padding:0}}>
                            {/* <div className="col-md-6">
                                <div style={{maxWidth: '250px', paddingTop: '1rem', display: 'none'}}>
                                    <label>Kod promocyjny</label>
                                    <input type="text" className="form-control" placeholder="Kod promocyjny" />
                                    <button type="button" className="btn btn-success mt-2" style={{borderRadius: '50px'}}>
                                        Dodaj
                                    </button>
                                </div>
                            </div> */}
                            <div className="col-md-12 text-center">
                                <div style={{fontWeight: 700, padding: '1rem', fontSize: '18px', border: '1px solid #e5bc42', borderRadius: '5rem', color: '#e5bc42'}}>
                                    Razem: <span className="latoFont">{summary} zł</span>
                                </div>
                                {/* <button 
                                    type="button" 
                                    className="btn btn-success mt-3" 
                                    style={{borderRadius: '50px', padding: '.7rem 1.7rem'}}
                                    onClick={()=>handleClick(driver, pasager)}>
                                    ZAPŁAĆ
                                </button> */}
                            </div>
                            <div className="col-12 text-center py-3">
                                <i>*dla pierwszych 10 klientów przygotowaliśmy ZŁOTE BILETY kozacko obcięte o 10%</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-12" style={{padding: '.75rem'}}>
                        <h4 style={{color: '#e5bc42'}}>
                            Dane
                            <FontAwesomeIcon style={{marginLeft: '.5rem', fontSize: '22px'}} icon={faUser} /> 
                        </h4>
                    </div>
                </div>
                <Person driver={driver} pasager={pasager} />
            </div>
        </div>
    )
}

export default Basket;