import React from 'react';
import { Col, Container, Row } from 'reactstrap';

const Info = () => {
    return(
        <Container>
            <Row style={{fontSize: '15px', paddingBottom: '.75rem'}}>
                <Col xs={12} style={{padding: '.75rem'}}>
                    <h4 style={{color: '#e5bc42', textAlign: 'center'}}>Nocne przejazdy</h4>
                </Col>
                <Col xs={12} style={{textAlign: 'center', paddingBottom: '1rem', paddingTop: '.5rem', fontWeight: '600'}}>
                    <div style={{paddingBottom: '1rem'}}>
                        Szukasz pomysłu na prezent, a może sam patrząc na naszą złotą bestię marzysz o złapaniu za jej kierownicę?
                    </div>
                    <div>
                        Wychodzimy naprzeciw oczekiwaniom naszych Klientów.
                    </div>
                    <div style={{paddingBottom: '1rem'}}>
                        <span  style={{color:"#e5bc42",fontWeight:"bold"}}>Złoty bilet</span> na przejażdżkę Kozacką Furą!
                    </div>
                    <div style={{paddingBottom: '1rem'}}>
                        Dwie opcję biletu, a jedna przejażdżka która zapadnie w Waszą pamięć na bardzo długo.
                    </div>
                    <div>
                        Kwota dotyczy wynajmu samochodu na odcinku <span className="latoFont">15 km.</span>
                    </div>
                    <div style={{paddingBottom: '1rem', color:"#e5bc42"}}>
                        Posiadacz biletu ma prawo zabrać na przejażdżkę dodatkowo dwie osoby.
                    </div>
                    <div style={{paddingBottom: '.5rem'}}>
                        INFORMACJE DODATKOWE
                    </div>
                    <div>
                        Przejazdy będą odbywać się na terenie województwa małopolskiego. 
                    </div>
                    <div>
                        Usługa realizowana jest w ustalonym wcześniej terminie i miejscu w godzinach wieczornych.
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Info;